import { render, staticRenderFns } from "./TaskButton.vue?vue&type=template&id=7c741a36&scoped=true"
import script from "./TaskButton.vue?vue&type=script&lang=js"
export * from "./TaskButton.vue?vue&type=script&lang=js"
import style0 from "./TaskButton.vue?vue&type=style&index=0&id=7c741a36&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c741a36",
  null
  
)

export default component.exports