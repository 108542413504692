<template>
  <div :style="style" class="task-container-color"></div>
</template>

<script>
export default {
  name: "TaskColor",
  props: ["color", "hoverActive"],
  data() {
    return {
      style: {
        backgroundColor: "",
        borderTop: "1px solid #E6E8EC",
        borderLeft: "1px solid #E6E8EC",
        borderBottom: "1px solid #E6E8EC",
      }
    }
  },
  created() {
    this.style.backgroundColor = this.color;
  },
  watch: {
    color() {
      this.style.backgroundColor = this.color;
    },
    hoverActive() {
      if (this.hoverActive) {
        this.style.borderTop = "2px solid " + this.color;
        this.style.borderLeft = "2px solid " + this.color;
        this.style.borderBottom = "2px solid " + this.color;
      } else {
        this.style.borderTop = "1px solid #E6E8EC";
        this.style.borderLeft = "1px solid #E6E8EC";
        this.style.borderBottom = "1px solid #E6E8EC";
      }
    }
  }
}
</script>

<style scoped>
.task-container-color {
  width: 8px;
  min-width: 8px;
  max-width: 8px;
  border-radius: 8px 0 0 8px;
}
</style>