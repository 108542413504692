<template>
  <v-tooltip right>
    <template v-slot:activator="{on}">
      <div v-on="on" @mouseover="hoverButton(true)" @mouseleave="hoverButton(false)" @click="buttonClicked"
           class="task-container-button" :style="style">
        <template v-if="showAnimation">
          <Lottie :width="20" :height="20"  :options="defaultOptions" v-on:animCreated="handleAnimation" />
        </template>
        <img v-if="buttonIcon && !showAnimation" :src="require(`@/assets/images/${buttonIcon}`)" alt=""/>
      </div>
    </template>
    <span>{{ $t(buttonText) }}</span>
  </v-tooltip>
</template>

<script>
import Lottie from 'vue-lottie/src/lottie';
import animationData from '@/assets/lottie/checkmark.json';

export default {
  name: "TaskButton",
  props: ["type", "category", "task", "color", "hoverActive", "isActive"],
  components: {Lottie},
  data() {
    return {
      anim: null,
      showAnimation: false,
      defaultOptions: {animationData: animationData, autoplay: false, loop: false},
      animationSpeed: 1,
      style: {
        borderTop: "1px solid #E6E8EC",
        borderRight: "1px solid #E6E8EC",
        borderBottom: "1px solid #E6E8EC",
        backgroundColor: "#F4F5F7",
        width: "36px",
        minWidth: "36px",
        maxWidth: "36px"
      },
      buttonIcon: "",
      buttonText: "",
      hoveringButton: false,
    }
  },
  created() {
    if (this.category === 'task') {
      this.buttonIcon = "checkbox_empty_20_20.svg"
      this.buttonText = "MarkCompleted"
      if (this.isActive) {
        this.style.backgroundColor = "#0AAF60";
        this.buttonIcon = "checkbox_calendar_white_20_20.svg"
      } else {
        this.backgroundColor = "#F4F5F7";
      }
    }
    if (this.category === 'open_house' || (this.type.value === "open_house" && this.category === "event")) {
      if (this.task.numberOfParticipants) {
        this.style.backgroundColor = "#0AAF60";
        this.buttonIcon = "add_white_20_20.svg"
        this.buttonText = "AddParticipantsInfoPanel"
      } else {
        this.buttonIcon = "add_grey_20_20.svg"
        this.buttonText = "AddParticipantsInfoPanel"
      }
    }
  },
  watch: {
    hoverActive() {
      if (this.hoverActive) {
        this.style.borderTop = "2px solid " + this.type.color;
        this.style.borderRight = "2px solid " + this.type.color;
        this.style.borderBottom = "2px solid " + this.type.color;
        this.style.paddingLeft = "1px"
      } else {
        this.style.borderTop = "1px solid #E6E8EC";
        this.style.borderRight = "1px solid #E6E8EC";
        this.style.borderBottom = "1px solid #E6E8EC";
        this.style.paddingLeft = "0"
      }
    },
    isActive(value) {
      if (value) {
        this.style.backgroundColor = "#0AAF60";
        this.buttonIcon = "checkbox_calendar_white_20_20.svg"
      } else {
        this.style.backgroundColor = "#F4F5F7";
        if (this.hoveringButton) {
          this.buttonIcon = "checkbox_calendar_20_20.svg"
        } else {
          this.buttonIcon = "checkbox_empty_20_20.svg"
        }
      }
    },
    "task": function () {
      if (this.category === 'open_house' || (this.type.value === "open_house" && this.category === "event")) {
        if (this.task.numberOfParticipants) {
          this.style.backgroundColor = "#0AAF60";
          this.buttonIcon = "add_white_20_20.svg"
          this.buttonText = "AddParticipantsInfoPanel"
        } else {
          this.style.backgroundColor = "#F4F5F7";
          this.buttonIcon = "add_grey_20_20.svg"
          this.buttonText = "AddParticipantsInfoPanel"
        }
      }
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
    buttonClicked() {
      if (this.category === "task" && !this.showAnimation && !this.isActive) {
        this.showAnimation = true;
        this.$nextTick(() => {
          this.anim.addEventListener('complete', () => this.animationFinished());
          this.anim.play();
        })
        this.style.backgroundColor = '#0AAF60';
        this.style.transition = "background-color 1000ms linear";
      } else {
        this.$emit('click');
        this.style.transition = "none";
      }
    },
    animationFinished() {
      this.$emit('click');
      this.showAnimation = false;
    },
    hoverButton(value) {
      if (this.isActive) {
        this.buttonText = "MarkNotDone";
        return;
      }
      if (this.category === 'task') {
        this.buttonText = "MarkCompleted"
      }
      if (value) {
        if (this.category === 'task') {
          this.buttonIcon = "checkbox_calendar_20_20.svg"
          this.hoveringButton = true;
        }
      } else {
        if (this.category === 'task') {
          this.buttonIcon = "checkbox_empty_20_20.svg"
          this.hoveringButton = false;
        }
      }
    },
  },
}
</script>

<style scoped>
.task-container-button {
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>