var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({
          staticClass: "task-container-button",
          style: _vm.style,
          on: {
            "mouseover": function ($event) {
              return _vm.hoverButton(true);
            },
            "mouseleave": function ($event) {
              return _vm.hoverButton(false);
            },
            "click": _vm.buttonClicked
          }
        }, on), [_vm.showAnimation ? [_c('Lottie', {
          attrs: {
            "width": 20,
            "height": 20,
            "options": _vm.defaultOptions
          },
          on: {
            "animCreated": _vm.handleAnimation
          }
        })] : _vm._e(), _vm.buttonIcon && !_vm.showAnimation ? _c('img', {
          attrs: {
            "src": require(`@/assets/images/${_vm.buttonIcon}`),
            "alt": ""
          }
        }) : _vm._e()], 2)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t(_vm.buttonText)))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }